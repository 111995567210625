import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Spin } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { Logo } from '../../assets/svg/index';
import { DB } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { signInWithEmailPassword } from '../../firebase/collections/auth';
import { getLoggedUserDetails } from '../../firebase/collections/users';
import useRouter from '../../hooks/useRouter';
import './auth.less';

const { required, email } = formValidatorRules;

const Login = () => {
  const [form] = Form.useForm();
  const { navigate } = useRouter();
  const { initializeAuth } = useContext(AppContext);
  const [loginLoading, setLoginLoading] = useState(false);

  function successCallback(accessToken, userData, refreshToken) {
    initializeAuth(accessToken, userData, refreshToken);
    navigate('/', { replace: true });
  }

  const onFinish = async (values) => {
    setLoginLoading(true);
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
        password: values?.password?.trim(),
      };
      const response = await signInWithEmailPassword(formValues);
      if (response?.user) {
        const { accessToken, uid } = response?.user || {};
        const userData = await getLoggedUserDetails(DB?.USERS, uid);
        if (successCallback) {
          successCallback(accessToken, userData);
          setLoginLoading(false);
        }
      } else {
        form?.setFieldsValue(values);
        setLoginLoading(false);
      }
    } catch (error) {
      setLoginLoading(false);
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={loginLoading} wrapperClassName="full-width">
            <div className="text-center  mb-48">
              <Logo />
            </div>
            <Form
              name="Login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              size="large"
              form={form}
            >
              <Form.Item
                name="email"
                rules={[{ required, message: 'Please enter email!' }, email]}
              >
                <Input prefix={<UserOutlined />} placeholder="Enter email" />
              </Form.Item>

              <Form.Item
                name="password"
                className="mb-24"
                rules={[{ required, message: 'Please enter password!' }]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter password"
                />
              </Form.Item>
              <Form.Item className=" full-width mb-8">
                <Button type="primary" className="full-width" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <div className="text-center login-copyrights">
          Logicwind © {new Date()?.getFullYear()}
        </div>
      </div>
    </div>
  );
};

export default Login;
