import { useEffect } from 'react';
import { logout } from '../../firebase/collections/auth';

const Logout = () => {
  const handleLogout = async () => {
    await logout();
  };

  useEffect(() => {
    handleLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Logout;
