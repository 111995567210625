import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';
import LoaderComponent from './components/LoaderComponent';

const PrivateRoute = () => {
  const { authLoading, authUser } = useContext(AppContext);

  if (authLoading) {
    return <LoaderComponent />;
  }
  return !authUser ? <Navigate to={ROUTES?.LOGIN} /> : <Outlet />;
};

export default PrivateRoute;
