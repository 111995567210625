import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import { db } from '..';

export const updateData = async (collectionName, documentId, data) => {
  const obj = {};
  Object?.assign(obj, data);
  Object?.keys(obj)?.forEach(
    (key) => obj?.[key] === undefined && delete obj?.[key],
  );
  try {
    const docRef = doc(db, collectionName, documentId);
    await setDoc(docRef, obj, { merge: true });
    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error Updating:', err);
  }
};

export const createData = async (collectionName, data) => {
  const obj = {};
  Object?.assign(obj, data);
  Object?.keys(obj)?.forEach(
    (key) => obj?.[key] === undefined && delete obj?.[key],
  );
  try {
    const { id } = await addDoc(collection(db, collectionName), {
      ...obj,
      created: Timestamp?.now(),
    });
    await updateData(collectionName, id, { id });
    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error Injecting:', err);
  }
};

export const deleteData = async (collectionName, documentId) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    await deleteDoc(docRef);
    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error Deleting:', err);
  }
};

export const deleteMultipleData = async (collectionName, documentIds = []) => {
  try {
    const result = documentIds?.map(async (id) => {
      await deleteDoc(doc(db, collectionName, id));
    });
    Promise?.all(result); // check all promise resolved inside map
    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error Deleting:', err);
  }
};

export const getDataByDocumentId = async (collectionName, documentId) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(docRef);
    if (docSnap?.exists()) {
      return docSnap?.data();
    }
    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error Fetching:', err);
  }
};
