import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Space, Upload } from 'antd';
import { updateProfile } from 'firebase/auth';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { DB, STORAGE } from '../../common/constants';
import {
  beforeUpload,
  beforeUploadImage,
  formValidatorRules,
  handleProtectedNavigation,
  uploadFileToFirebaseStorage,
} from '../../common/utils';
import RouterPrompt from '../../components/RouterPrompt';
import { toastDestroy, toastLoading } from '../../components/ToastContainer';
import { auth } from '../../firebase';
import { updateUser } from '../../firebase/collections/users';
import useAuthState from '../../hooks/useAuthState';
import useRouter from '../../hooks/useRouter';

const { required, name, email } = formValidatorRules;

function Profile() {
  const { user } = useAuthState();
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form?.useForm();
  const { navigate } = useRouter();
  const [showPrompt, setShowPrompt] = useState(false);
  const [isPrompt, setIsPrompt] = useState(false);
  const [updatedImg, setUpdatedImg] = useState(null);
  const { dispatch, currentUser } = useContext(AppContext);

  const handleChange = async ({ file }) => {
    if (beforeUploadImage(file)) {
      toastLoading('Uploading...');
      const URL = await uploadFileToFirebaseStorage(
        `${STORAGE?.PROFILE}`,
        file,
      ); // it will return uploaded image URL
      if (URL) {
        await updateProfile(auth?.currentUser, { photoURL: URL });
        const isUpdated = await updateUser(
          DB?.USERS,
          {
            ...user,
            profilePhoto: URL,
          },
          user?.id,
        );
        if (isUpdated) {
          dispatch({
            type: 'SET_CURRENT_USER',
            data: {
              ...currentUser,
              photoURL: URL,
            },
          });
          setUpdatedImg(URL);
          form?.setFieldValue('profilePhoto', URL);
        }
      }
      toastDestroy();
    }
  };

  const uploadButton = (
    <div>
      {btnLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="mt-8">Upload</div>
    </div>
  );

  const handleBack = () => {
    setIsPrompt(!handleProtectedNavigation(!showPrompt, navigate, -1));
  };

  const handleShowPrompt = () => {
    setShowPrompt(true);
  };

  const handleOk = () => {
    handleProtectedNavigation(true, navigate, -1);
  };

  const handleClose = () => {
    setIsPrompt(false);
  };

  const onFinish = async (values) => {
    try {
      setBtnLoading(true);
      setShowPrompt(false);
      const userData = {
        ...user,
        firstName: values?.firstName?.trim(),
        lastName: values?.lastName?.trim(),
        profilePhoto: updatedImg || values?.profilePhoto?.trim(),
        // commented as of now.
        // profileImage: values?.profileImage
      };
      await updateProfile(auth?.currentUser, {
        photoURL: updatedImg || values?.profilePhoto?.trim(),
        displayName: `${values?.firstName?.trim()} ${values?.lastName?.trim()}`,
      });
      const response = await updateUser(DB?.USERS, userData, user?.id);
      if (response) {
        dispatch({
          type: 'SET_CURRENT_USER',
          data: {
            ...currentUser,
            photoURL: updatedImg || values?.profilePhoto?.trim(),
            displayName: `${values?.firstName?.trim()} ${values?.lastName?.trim()}`,
          },
        });
        setBtnLoading(false);
      }
    } catch (error) {
      setBtnLoading(false);
    }
  };
  return (
    <>
      {user && (
        <Form
          form={form}
          className="sticky-action-form"
          onFieldsChange={handleShowPrompt}
          layout="vertical"
          initialValues={user}
          onFinish={onFinish}
        >
          <Card
            className="ant-body-scroll"
            title="Profile"
            actions={[
              <div key="actionbutton" className="text-right">
                <Space>
                  <Button onClick={handleBack} disabled={btnLoading}>
                    Cancel
                  </Button>
                  <Button type="primary" loading={btnLoading} htmlType="submit">
                    Save
                  </Button>
                </Space>
              </div>,
            ]}
          >
            <div className="card-body-wrapper">
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={24} xl={24}>
                  <Form.Item name="profilePhoto" label="Profile photo">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      accept="image/png, image/jpeg"
                      onChange={handleChange}
                      fileList={[]}
                    >
                      {user?.profilePhoto ? (
                        <img
                          src={updatedImg || user?.profilePhoto}
                          alt="avatar"
                          className="full-width"
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8} xl={8}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      { ...required, message: 'Please Enter First Name' },
                      name,
                    ]}
                  >
                    <Input placeholder="Enter First Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8} xl={8}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      { ...required, message: 'Please Enter Last Name' },
                      name,
                    ]}
                  >
                    <Input placeholder="Enter Last Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8} xl={8}>
                  <Form.Item
                    name="email"
                    label="Email Id"
                    rules={[
                      { ...required, message: 'Please Enter Email' },
                      email,
                    ]}
                  >
                    <Input disabled placeholder="Enter Email Id" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Card>
        </Form>
      )}
      <RouterPrompt
        isPrompt={isPrompt}
        handleOK={handleOk}
        handleCancel={handleClose}
      />
    </>
  );
}
export default Profile;
