import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Statistic,
  Table,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { onValue, ref } from 'firebase/database';
import { map, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { GUTTER_VARIATIONS } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { toastError } from '../../components/ToastContainer';
import { database } from '../../firebase';
import QuizServices from '../../firebase/collections/quiz';
import './sessions.less';

function SessionDetails() {
  const [quizList, setQuizList] = useState([]);
  const [sessionsList, setSessionsList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(true);

  // table column
  const columns = [
    {
      title: 'Username',
      dataIndex: 'userName',
      render: (action, { userName }) => (
        <Typography.Text>{userName}</Typography.Text>
      ),
    },
    {
      title: 'Score',
      dataIndex: 'score',
      render: (action, { score }) => <Typography.Text>{score}</Typography.Text>,
    },
  ];

  const fetchList = async () => {
    const quizRef = ref(database, 'quiz/');
    onValue(quizRef, (snapshot) => {
      if (snapshot?.exists()) {
        const dataArray = [];
        Object?.entries(snapshot?.val())?.map((item) => {
          dataArray?.push({
            ...item?.[1],
            id: item?.[0],
          });
          return item;
        });
        setQuizList(dataArray?.reverse());
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleFinish = ({ quizId, sessionId }) => {
    // eslint-disable-next-line no-console
    const userRef = ref(database, `quiz/${quizId}/sessions/${sessionId}`);
    QuizServices?.getDetails(userRef)?.then((snapshot) => {
      if (snapshot?.exists()) {
        const { users } = snapshot?.val();
        const dataArray = users
          ? Object?.entries(users)?.map((data) => ({
              id: data?.[0],
              ...data?.[1],
            }))
          : [];
        if (dataArray?.length > 0) {
          const sortedArray = orderBy(dataArray, ['score'], ['desc']);
          setMemberList(sortedArray);
        } else {
          toastError('No users found!');
          setMemberList([]);
        }
      } else {
        setMemberList([]);
        toastError('No data found!');
      }
    });
  };

  const handleQuizChange = (event) => {
    const data = quizList?.filter((quizItem) => quizItem?.id === event);
    if (data?.length > 0) {
      if (data?.[0]?.sessions) {
        const dataArray = [];
        Object?.entries(data?.[0]?.sessions)?.map((item) => {
          dataArray?.push({
            ...item?.[1],
            id: item?.[0],
          });
          return item;
        });
        setSessionsList(dataArray?.reverse());
      } else {
        setSessionsList([]);
        toastError('No sessions found!');
      }
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  if (loading) return <LoaderComponent />;
  return (
    <div className="movie-details">
      <Form
        name="Quiz Modal"
        className="quiz-modal-form"
        form={form}
        layout="vertical"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Typography.Title level={3}>Sessions Details</Typography.Title>
            <Divider />
          </Col>
          <Col span={8}>
            <Form.Item
              name="quizId"
              label="Quiz List"
              rules={[
                {
                  required: true,
                  message: 'Please select quiz!',
                },
              ]}
            >
              <Select
                placeholder="Select Quiz"
                onChange={(event) => handleQuizChange(event)}
              >
                {map(quizList, (list) => (
                  <Select.Option key={list?.id} value={list?.id}>
                    {list?.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="sessionId"
              label="Sessions List"
              rules={[
                {
                  required: true,
                  message: 'Please select session!',
                },
              ]}
            >
              <Select placeholder="Select Session">
                {map(sessionsList, (list) => (
                  <Select.Option key={list?.id} value={list?.id}>
                    {list?.sesstionTime
                      ? dayjs(list?.sesstionTime)?.format(
                          'DD-MM-YYYY h:mm:ss A',
                        )
                      : '-'}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label=" ">
              <Button type="primary" htmlType="submit" loading={loading}>
                Get Details
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {memberList?.length > 0 && (
        <>
          <div className="site-statistic-demo-card mt-32 mb-32">
            <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Card>
                  <Statistic
                    title="Total Users"
                    value={memberList?.length}
                    precision={0}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Card>
                  <Statistic
                    title="High Score"
                    value={memberList?.[0]?.score}
                    precision={0}
                    valueStyle={{ color: '#ebac0b' }}
                  />
                </Card>
              </Col>
            </Row>
          </div>
          <Divider />
          <div className="table-wrapper">
            <Table
              className="user-list-table"
              columns={columns}
              dataSource={memberList}
              loading={loading}
              pagination={false}
            />
          </div>
        </>
      )}
    </div>
  );
}
export default SessionDetails;
