import { DownOutlined, IdcardOutlined, LoginOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Space } from 'antd';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';

const UserProfile = () => {
  const {
    authUser: { displayName, photoURL },
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const items = [
    {
      key: 'profile',
      label: <Link to={ROUTES?.PROFILE}>My Profile</Link>,
      icon: <IdcardOutlined />,
    },
    {
      key: 'logout',
      label: <Link to={ROUTES?.LOGOUT}>Logout</Link>,
      icon: <LoginOutlined />,
    },
  ];

  return (
    <Dropdown
      menu={{ items, selectedKeys: [`${pathname?.split('/')?.[1]}`] }}
      trigger={['click']}
    >
      <Space className="pointer d-flex align-center gap-4" size="small">
        {photoURL ? (
          <Avatar alt="Avatar" src={photoURL} />
        ) : (
          <Avatar alt="Avatar">
            {displayName?.split(' ')?.[0]?.charAt(0)?.toUpperCase() || null}
            {displayName?.split(' ')?.[1]?.charAt(0)?.toUpperCase() || null}
          </Avatar>
        )}
        <span className="m-hide">{displayName}</span>
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export default UserProfile;
