import { get, push, ref, update } from 'firebase/database';
import { database } from '..';

const dbRef = ref(database, `quiz`);

class QuizServices {
  getAll() {
    return get(dbRef);
  }

  getDetails(payLoadRef) {
    return get(payLoadRef);
  }

  create(payload) {
    return push(dbRef, payload);
  }

  update(key, value) {
    const updates = {};
    updates[`/quiz/${key}`] = value;
    return update(ref(database), updates);
  }

  delete(key) {
    const updates = {};
    updates[`/quiz/${key}`] = null;
    return update(ref(database), updates);
  }
}

export default new QuizServices();
