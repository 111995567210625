import {
  AppstoreOutlined,
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import Meta from 'antd/es/card/Meta';
import { onValue, ref } from 'firebase/database';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import imagePlaceholder from '../../assets/images/imagePlaceholder.png';
import {
  DB,
  QUESTION_ID,
  QUESTION_TYPE,
  START_LIST,
  STATUS_LIST,
} from '../../common/constants';
import { toastSuccess } from '../../components/ToastContainer';
import { auth, database } from '../../firebase';
import QuizServices from '../../firebase/collections/quiz';
import { deleteMultipleData } from '../../firebase/collections/utils';
import './users.less';

function SessionDetails() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [info, setInfo] = useState({});
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  // commenting for future use
  // const [updatedImg, setUpdatedImg] = useState(null);

  const [view, setView] = useState('LIST');

  const fetchList = async () => {
    const quizRef = ref(database, `quiz/`);
    onValue(quizRef, (snapshot) => {
      if (snapshot?.exists()) {
        const dataArray = [];
        Object?.entries(snapshot?.val())?.map((item) => {
          dataArray?.push({
            ...item?.[1],
            id: item?.[0],
          });
          return item;
        });
        setDataList(dataArray?.reverse());
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleDeleteSelected = async () => {
    setBtnLoading(true);
    const isDeleted = await deleteMultipleData(DB?.MEMBER, selectedRowKeys);
    if (isDeleted) {
      toastSuccess('Members deleted!');
      setBtnLoading(false);
      setSelectedRowKeys([]);
    }
  };

  const handleDelete = async (id) => {
    setBtnLoading(true);
    QuizServices.delete(id)
      .then(async () => {
        toastSuccess('Quiz deleted!');
        setBtnLoading(false);
        setSelectedRowKeys([]);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  };

  // table column
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      render: (action, record) => (
        <Typography.Text>{record?.title}</Typography.Text>
      ),
    },
    {
      title: 'Published',
      dataIndex: 'isActive',
      render: (action, { isActive }) => (
        <Typography.Text>
          {isActive ? 'Published' : 'Unpublished'}
        </Typography.Text>
      ),
    },
    {
      title: 'Action',
      render: (action, record) => (
        <div className="d-flex">
          <EditOutlined
            className="mr-8"
            onClick={() => {
              form?.setFieldsValue(record);
              setIsModal(true);
              setInfo(record);
            }}
          />
          <Popconfirm
            title="Delete quiz"
            description="Are you sure to delete this quiz?"
            onConfirm={() => handleDelete(record?.id)}
            okText="Delete"
          >
            <DeleteOutlined className="delete-icon mr-8" />
          </Popconfirm>

          <Tag color="green" className="copy-tag">
            <Typography.Paragraph
              className="copy-text"
              copyable={{
                icon: [
                  <CopyOutlined key="copy-icon" color="#ffffff" />,
                  <CheckOutlined key="copied-icon" />,
                ],
                tooltips: 'Copy Quiz URL',
                text: `${process.env.REACT_APP_TV_APP_URL}?quizId=${record?.id}`,
              }}
            >
              Link
            </Typography.Paragraph>
          </Tag>
        </div>
      ),
    },
  ];

  // commenting for future use
  // const handleChange = async ({ file }) => {
  //   if (beforeUploadImage(file)) {
  //     toastLoading('Uploading...');
  //     const URL = await uploadFileToFirebaseStorage(`${STORAGE?.QUIZ}`, file); // it will return uploaded image URL
  //     if (URL) {
  //       setUpdatedImg(URL);
  //       form?.setFieldValue('qrCode', URL);
  //     }
  //     toastDestroy();
  //   }
  // };

  useEffect(() => {
    fetchList();
  }, []);

  const handleCancel = () => {
    setIsModal(false);
  };

  const handleFinish = async (values) => {
    if (!info) {
      QuizServices.create({ ...values, createdBy: auth?.currentUser?.email })
        .then(async () => {
          setBtnLoading(true);
          toastSuccess('Quiz created successfully!');
          form?.resetFields();
          setIsModal(false);
          setBtnLoading(false);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
    } else {
      QuizServices.update(info?.id, {
        ...info,
        ...values,
        lastEditedBy: auth?.currentUser?.email,
      })
        .then(async () => {
          setBtnLoading(true);
          toastSuccess('Quiz updated successfully!');
          setIsModal(false);
          setBtnLoading(false);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
    }
  };

  const handleOpenModal = () => {
    setInfo(null);
    setIsModal(true);
    form?.resetFields();
  };

  // commenting for future use
  // const uploadButton = (
  //   <div>
  //     {btnLoading ? <LoadingOutlined /> : <PlusOutlined />}
  //     <div className="mt-8">Upload</div>
  //   </div>
  // );

  return (
    <div className="movie-details">
      {!isModal ? (
        <>
          <div className="d-flex justify-between mb-16">
            <Space>
              <Button type="primary" onClick={handleOpenModal}>
                Add Quiz
              </Button>
              {selectedRowKeys?.length > 0 && view === 'LIST' && (
                <Popconfirm
                  title="Delete quiz"
                  description="Are you sure to delete these quiz?"
                  onConfirm={handleDeleteSelected}
                  okText="Delete"
                >
                  <Button type="primary">
                    Delete ({selectedRowKeys?.length})
                  </Button>
                </Popconfirm>
              )}
            </Space>
            <Space>
              <Button
                type={view === 'LIST' ? 'primary' : 'default'}
                icon={<UnorderedListOutlined />}
                onClick={() => setView('LIST')}
                title="List View"
              />
              <Button
                type={view === 'LIST' ? 'default' : 'primary'}
                icon={<AppstoreOutlined />}
                onClick={() => setView('GRID')}
                title="Grid View"
              />
            </Space>
          </div>
          {view === 'LIST' ? (
            <div className="table-wrapper">
              <Table
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={dataList}
                loading={loading}
                pagination={false}
              />
            </div>
          ) : (
            <div className="card-body-wrapper">
              {dataList?.length > 0 ? (
                <Row gutter={[16, 16]}>
                  {map(dataList, (data) => (
                    <Col key={data?.id} xs={24} sm={12} md={8} lg={6} xl={6}>
                      <Card
                        cover={
                          <img
                            alt="example"
                            src={imagePlaceholder}
                            className="movie-poster"
                          />
                        }
                        className="movie-tile pointer full-width"
                        actions={[
                          <Button
                            type="text"
                            key="edit"
                            onClick={() => {
                              form?.setFieldsValue(data);
                              setIsModal(true);
                              setInfo(data);
                            }}
                          >
                            <EditOutlined />
                          </Button>,
                          <Popconfirm
                            key="popup"
                            title="Delete quiz"
                            description="Are you sure to delete this Quiz?"
                            onConfirm={() => handleDelete(data?.id)}
                            okText="Delete"
                          >
                            <Button key="delete" type="text">
                              <DeleteOutlined className="delete-icon" />
                            </Button>
                          </Popconfirm>,
                        ]}
                      >
                        <Meta
                          title={`${data?.firstName || ''} ${
                            data?.lastName || ''
                          }`}
                          description={
                            <Typography.Paragraph ellipsis={{ rows: 2 }}>
                              {data?.title || '-'}
                            </Typography.Paragraph>
                          }
                        />
                      </Card>
                    </Col>
                  ))}
                </Row>
              ) : (
                <Empty description="No data" />
              )}
            </div>
          )}
        </>
      ) : (
        <Form
          name="Quiz Modal"
          className="quiz-modal-form"
          form={form}
          layout="vertical"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={handleFinish}
          autoComplete="off"
        >
          <div className="d-flex justify-between">
            <Button
              type="default"
              onClick={handleCancel}
              icon={<LeftOutlined />}
            >
              Go Back
            </Button>
            <Space>
              <Button type="default" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={btnLoading}>
                Save
              </Button>
            </Space>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Typography.Title level={3}>Quiz Details</Typography.Title>
              <Divider />
            </Col>
            <Col span={24}>
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Title required!',
                  },
                ]}
              >
                <Input placeholder="Enter Title" maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Description required!',
                  },
                ]}
              >
                <Input placeholder="Enter Title" maxLength={250} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="isActive"
                label="Publish Status"
                rules={[
                  {
                    required: true,
                    message: 'Status required!',
                  },
                ]}
              >
                <Select placeholder="Select Status">
                  {map(STATUS_LIST, (list) => (
                    <Select.Option key={list?.value} value={list?.value}>
                      {list?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* commenting for future use */}
            {/* <Col span={8}>
              <Form.Item
                name="isExpired"
                label="Expiry Status"
                rules={[
                  {
                    required: true,
                    message: 'Status required!',
                  },
                ]}
              >
                <Select placeholder="Select Expiry Status">
                  {map(EXPIRY_LIST, (list) => (
                    <Select.Option key={list?.value} value={list?.value}>
                      {list?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
            <Col span={8}>
              <Form.Item
                name="isStarted"
                label="Quiz Status"
                rules={[
                  {
                    required: true,
                    message: 'Status required!',
                  },
                ]}
              >
                <Select placeholder="Select Status">
                  {map(START_LIST, (list) => (
                    <Select.Option key={list?.value} value={list?.value}>
                      {list?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Quiz Start Time (seconds)"
                name="quizStartTime"
                rules={[
                  {
                    required: true,
                    message: 'Quiz Start Time required!',
                  },
                ]}
              >
                <InputNumber placeholder="Enter Time (seconds)" min={1} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Interval Time (seconds)"
                name="intervalTime"
                rules={[
                  {
                    required: true,
                    message: 'Interval Time required!',
                  },
                ]}
              >
                <InputNumber placeholder="Enter Time (seconds)" min={1} />
              </Form.Item>
            </Col>
            {/* commenting for future use */}
            {/* <Col span={8}>
              <Form.Item
                label="QR Code"
                name="qrCode"
                rules={[
                  {
                    required: true,
                    message: 'QR Code required!',
                  },
                ]}
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  accept="image/png, image/jpeg"
                  onChange={handleChange}
                  fileList={[]}
                >
                  {form?.getFieldValue('qrCode') ? (
                    <img
                      src={updatedImg || form?.getFieldValue('qrCode')}
                      alt="avatar"
                      className="full-width"
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <Typography.Title level={3}>Q&A Section</Typography.Title>
              <Divider />
            </Col>
            <Col span={24}>
              <Form.List
                name="questions"
                initialValue={[{ title: '', type: '', correctAnswer: '' }]}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row key={key} gutter={[16, 8]}>
                        <Col span={24}>
                          <Form.Item
                            {...restField}
                            label="Question Title"
                            name={[name, 'title']}
                            rules={[
                              {
                                required: true,
                                message: 'Title required!',
                              },
                            ]}
                          >
                            <Input placeholder="Title" />
                          </Form.Item>
                        </Col>

                        <Col span={11}>
                          <Form.Item
                            {...restField}
                            label="Question Type"
                            name={[name, 'type']}
                            rules={[
                              {
                                required: true,
                                message: 'Type required!',
                              },
                            ]}
                          >
                            <Select placeholder="Select Type">
                              {map(QUESTION_TYPE, (list) => (
                                <Select.Option
                                  key={list?.value}
                                  value={list?.value}
                                >
                                  {list?.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={11}>
                          <Form.Item
                            {...restField}
                            label="Correct Answer"
                            name={[name, 'correctAnswer']}
                            rules={[
                              {
                                required: true,
                                message: 'Answer required!',
                              },
                            ]}
                          >
                            <Select placeholder="Select Correct Answer">
                              {map(QUESTION_ID, (list) => (
                                <Select.Option
                                  key={list?.value}
                                  value={list?.value}
                                >
                                  {list?.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Col>
                        <Col span={24}>
                          <Form.List
                            name={[name, 'options']}
                            initialValue={[{ title: '', id: '' }]}
                          >
                            {(
                              innerFields,
                              { add: addInner, remove: removeInner },
                            ) => (
                              <>
                                {innerFields.map(
                                  ({
                                    key: keyInner,
                                    name: nameInner,
                                    ...restSubField
                                  }) => (
                                    <Row key={keyInner} gutter={16}>
                                      <Col span={8}>
                                        <Form.Item
                                          {...restSubField}
                                          name={[nameInner, 'title']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Title required!',
                                            },
                                          ]}
                                        >
                                          <Input placeholder="Write Options Here" />
                                        </Form.Item>
                                      </Col>
                                      <Col span={8}>
                                        <Form.Item
                                          {...restSubField}
                                          name={[nameInner, 'id']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'ID required!',
                                            },
                                          ]}
                                        >
                                          <Select placeholder="Select ID">
                                            {map(QUESTION_ID, (list) => (
                                              <Select.Option
                                                key={list?.value}
                                                value={list?.value}
                                              >
                                                {list?.name}
                                              </Select.Option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col span={8}>
                                        <MinusCircleOutlined
                                          onClick={() => removeInner(nameInner)}
                                        />
                                      </Col>
                                    </Row>
                                  ),
                                )}
                                <Form.Item>
                                  <Button
                                    type="link"
                                    onClick={() => addInner()}
                                    danger
                                    icon={<PlusOutlined />}
                                  >
                                    Add Option
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Col>
                        <Divider />
                      </Row>
                    ))}
                    <Form.Item>
                      <Button
                        type="default"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add New Question
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
}

export default SessionDetails;
