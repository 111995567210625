import { Card, Col, Row, Statistic, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { GUTTER_VARIATIONS } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import QuizServices from '../../firebase/collections/quiz';
import './dashboard.less';

// individual css file that you can import
const { Title } = Typography;
const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);

  const fetchList = async () => {
    QuizServices?.getAll()
      ?.then((snapshot) => {
        if (snapshot?.exists()) {
          setCount(Object?.entries(snapshot?.val())?.length);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchList();
  }, []);

  if (loading) return <LoaderComponent />;
  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        Dashboard
      </Title>
      <div className="site-statistic-demo-card">
        <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Card>
              <Statistic
                title="Total Quiz"
                value={count}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
