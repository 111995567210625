import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { DB } from '../common/constants';
import { auth } from '../firebase';
import { getLoggedUserDetails } from '../firebase/collections/users';

function useAuthState() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const getUserFromUsers = async (collectionName, uid) => {
    const authUser = await getLoggedUserDetails(collectionName, uid);
    setUser(authUser);
    setLoading(false);
  };
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      getUserFromUsers(DB?.USERS, authUser?.uid);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return { user, loading };
}

export default useAuthState;
