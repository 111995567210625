import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '..';
import { checkDocumentExistence } from '../../common/utils';
import { toastError, toastSuccess } from '../../components/ToastContainer';

export const createUser = async (collectionName, data, uid) => {
  try {
    const docRef = doc(db, collectionName, uid);
    if (!(await checkDocumentExistence(collectionName, uid))) {
      await setDoc(docRef, data);
      return true;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const updateUser = async (collectionName, data, uid) => {
  try {
    const docRef = doc(db, collectionName, uid);
    await setDoc(docRef, data, { merge: true }); // merge will keep existing data, just overwrite new data
    toastSuccess('User updated successfully');
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const getLoggedUserDetails = async (collectionName, uid) => {
  try {
    const docRef = doc(db, collectionName, uid);
    const docSnap = await getDoc(docRef);
    if (docSnap?.exists()) {
      return docSnap?.data();
    }
    return toastError('User not found.');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
